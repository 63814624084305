import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, Form, Input, InputGroup, Label, Modal, ModalHeader, Row, Spinner } from "reactstrap"
import { getImageUrl, showToast, toFormdata } from "helpers/util"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import Select from "react-select"
import { useMemo } from "react"
import { UncontrolledTooltip } from "reactstrap"

const QuestionaryModal = ({ toggle, modal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [step, setStep] = useState(1)
  const [brands, setBrands] = useState(null)
  const {
    register,
    getValues,
    setValue,
    watch,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      doctor_type: "external-doctor",
      type: "e-script",
      value_type: "Link",
    },
  })

  const { data, isLoading } = useQuery(
    ["questionary-question"],
    () => api.getQuestionaryQuestion(),
    {
      onSuccess: res => {
        setValue(
          "question_ids",
          res.questions.map(item => item.id)
        )
      },
    }
  )

  const { data: pharmacy, isLoading: loadingPharmacy } = useQuery(
    ["select-pharmacy"],
    () => api.getAllPharmacy()
  )

  const { mutate, isLoading: submitLoading } = useMutation(
    params => {
      return api.submitQuestionary(params)
    },
    {
      onSuccess: res => {
        showToast(res.message, !res.status && "error")
        setLoading(false)
        if (!res.status) {
          showToast("Failed to submit questionary", "error")
          return
        }
        // toggle()
        setStep("complete")
        refresh()
      },
      onError: err => {
        showToast("Failed to submit pharmacy", "error")
      },
    }
  )

  const { mutate: checkCoupon } = useMutation(
    params => api.checkCoupon(params),
    {
      onSuccess: res => {
        if (!res.status) {
          return showToast(res.message, "error")
        }
        setValue("doctor_type", res.type)
        setValue("doctor", res.data.id)
        setSelectedDoctor(res.data)
        setBrands(res.data.brands)
        showToast("Coupon valid!")
      },
    }
  )

  const onSubmit = () => {
    if (!watch('pharmacy')) {
      return showToast('Pharmacy must be filled', 'error')
    }
    const user = JSON.parse(localStorage.getItem('authUser'))?.user
    const requirementId = user.requirement.find(
      item =>
        item.type == "e-script" && !item.answer
    )
    setValue("id", requirementId?.id)
    if (getValues("files")?.length > 0) {
      setValue("files", getValues('files')[0])
    }
    mutate(toFormdata(getValues()))
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))?.user
    const requirement = user.requirement.find(
      item =>
        item.type == "e-script" && !item.answer
    )
    if (user?.patient?.is_exist == 1 && !requirement) {
      setStep('start')
    } else if (user?.patient?.is_exist == 0) {
      setStep('start')
    }
  }, [])

  return (
    <Modal
      isOpen={modal}
      centered
      size="lg"
      contentClassName="h-unset"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        {step == "finish" ? (
          <h5 className="modal-title mt-0 mx-auto">Pharmacy</h5>
        ) : step == "complete" ? (
          <h5 className="modal-title mt-0">Complete</h5>
        ) : (
          <h5 className="modal-title mt-0">Patient Wellness Program</h5>
        )}
      </ModalHeader>
      <div className="modal-body">
        {isLoading ? (
          <Spinner className="ms-2 spinner-loading" color="success" />
        ) : (
          <div id="form-wrapper" className="py-5">
            <form className="w-100">
              {step == "start" ? (
                <>
                  <p>
                    It is a patient centric program, aimed to monitor the
                    wellness of the patients with subsidised medication. The
                    program primarily focuses on indications of chronical pain,
                    sleep disorder and anxiety.
                  </p>
                  <p>
                    Patients within Aeris Health or referred by our approved
                    prescribers will be able to participate the program. Please
                    have <b>a valid coupon code</b> and <b>an eRX token </b>
                    ready from your prescriber before proceeding with the
                    process. After providing your current wellness status by
                    finishing a questionnaire, the participants will be able to
                    redeem subsidised medication at <b>
                      12% discount on RRP
                    </b>{" "}
                    from our partner pharmacies with the following brands:
                  </p>
                  <ul className="ps-3">
                    <li>
                      <a
                        href="https://antg.com.au/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ANTG
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.beaconmedical.com.au/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Beacon Medical
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.kind.com.au/ "
                        target="_blank"
                        rel="noreferrer"
                      >
                        Kind Medical
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://tasmanianbotanics.com.au/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Tasmanian Botanics
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tilray.com.au/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Tilray Medical
                      </a>
                    </li>
                  </ul>
                </>
              ) : step == 0 ? (
                <>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-files-Input">Script</Label>
                        <select
                          className="form-control"
                          {...register("value_type")}
                        >
                          <option value="Document">Paper Script</option>
                          <option value="Link">eRX Link</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  {watch("value_type") == "Document" ? (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-files-Input">
                            Paper Script{" "}
                            <i
                              className="bx bx-question-mark rounded-circle border border-dark"
                              id="DocumentTooltip"
                            ></i>
                          </Label>
                          <UncontrolledTooltip
                            trigger="hover"
                            target="DocumentTooltip"
                          >
                            Upload the file of your current paper script.
                          </UncontrolledTooltip>
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                            {...register("files", {
                              required: "This field is required",
                            })}
                          />
                          {errors.files && (
                            <span className="form-text text-danger">
                              {errors.files[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-files-Input">
                            eRX Link{" "}
                            <i
                              className="bx bx-question-mark rounded-circle border border-dark"
                              id="ScriptTooltip"
                            ></i>
                          </Label>
                          <UncontrolledTooltip
                            trigger="hover"
                            target="ScriptTooltip"
                          >
                            The link of your current eRX script.
                          </UncontrolledTooltip>
                          <input
                            className="form-control"
                            type="text"
                            id="formFile"
                            placeholder="eRX Link"
                            {...register("value", {
                              required: "This field is required",
                            })}
                          />
                          {errors.value && (
                            <span className="form-text text-danger">
                              {errors.value[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md={12} className="mx-auto">
                      <div className="mb-3">
                        <Label htmlFor="doctor-coupon">
                          Coupon{" "}
                          <i
                            className="bx bx-question-mark rounded-circle border border-dark"
                            id="CouponTooltip"
                          ></i>
                        </Label>
                        <UncontrolledTooltip
                          trigger="click"
                          target="CouponTooltip"
                        >
                          The coupon code obtained from a prescriber
                          participating the Patient Wellness Program. If unsure,
                          please see the{" "}
                          <a
                            href="https://aeris.health/doctors"
                            target="_blank"
                            rel="noreferrer"
                            className="text-white text-underline"
                          >
                            <u>full list</u>
                          </a>{" "}
                          here.
                        </UncontrolledTooltip>
                        <InputGroup>
                          <input
                            className="form-control"
                            type="text"
                            id="doctor-coupon"
                            placeholder="Coupon"
                            {...register("coupon", {
                              required: "This field is required",
                            })}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={async () => {
                              if (await trigger("coupon")) {
                                checkCoupon(getValues())
                              }
                            }}
                            type="button"
                          >
                            Check Coupon
                          </button>
                        </InputGroup>
                        {selectedDoctor ? (
                          <>
                            <p className="text-success mt-3">
                              Coupon Valid for{" "}
                              {selectedDoctor?.user?.fullname ??
                                selectedDoctor?.name}{" "}
                              <i className="bx bx-check"></i>
                            </p>
                          </>
                        ) : null}
                        {errors.coupon && (
                          <span className="form-text text-danger">
                            {errors.coupon.message}
                          </span>
                        )}
                      </div>
                    </Col>
                    {brands ? (
                      <Col md={12} className="mx-auto">
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Select Brand
                          </Label>
                          <Select
                            options={brands}
                            onChange={e => {
                              setValue("doctor_brand", e.id)
                            }}
                            getOptionLabel={val => val.name}
                            getOptionValue={val => val.id}
                            classNamePrefix="select2-selection"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: base => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                          {errors.brands && (
                            <span className="form-text text-danger">
                              {errors.brands.message}
                            </span>
                          )}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ) : step == 1 ? (
                data?.questions?.map((question, i) => (
                  <>
                    {question.type == "rate" ? (
                      <div key={question.id} className="my-3">
                        <h3 className="text-center mb-3">
                          Q{i + 1}. {question.question}
                        </h3>
                        <div id="debt-amount-slider">
                          <input
                            type="radio"
                            name={`question_${i}_0`}
                            id={`question_${i}_0`}
                            value="0"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_0`}
                            data-debt-amount="0"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_1`}
                            id={`question_${i}_1`}
                            value="1"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_1`}
                            data-debt-amount="1"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_2`}
                            id={`question_${i}_2`}
                            value="2"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_2`}
                            data-debt-amount="2"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_3`}
                            id={`question_${i}_3`}
                            value="3"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_3`}
                            data-debt-amount="3"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_4`}
                            id={`question_${i}_4`}
                            value="4"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_4`}
                            data-debt-amount="4"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_5`}
                            id={`question_${i}_5`}
                            value="5"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_5`}
                            data-debt-amount="5"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_6`}
                            id={`question_${i}_6`}
                            value="6"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_6`}
                            data-debt-amount="6"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_7`}
                            id={`question_${i}_7`}
                            value="7"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_7`}
                            data-debt-amount="7"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_8`}
                            id={`question_${i}_8`}
                            value="8"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_8`}
                            data-debt-amount="8"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}_9`}
                            id={`question_${i}_9`}
                            value="9"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_9`}
                            data-debt-amount="9"
                          ></label>
                          <input
                            type="radio"
                            name={`question_${i}1_0`}
                            id={`question_${i}_10`}
                            value="10"
                            {...register(`answer.${i}`, {
                              required: "This field is required",
                            })}
                          />
                          <label
                            htmlFor={`question_${i}_10`}
                            data-debt-amount="10"
                          ></label>
                          <div id="debt-amount-pos"></div>
                        </div>
                        <div className="d-flex justify-content-between mt-5">
                          <h4 className="option-text">
                            {question.low_option_name}
                          </h4>
                          <h4 className="option-text">
                            {question.high_option_name}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div key={question.id}>
                        <h3 className="text-center mb-3">
                          Q{i + 1}. {question.question}
                        </h3>
                        <textarea
                          className="form-control"
                          id="formrow-description-Input"
                          placeholder="Answer"
                          onChange={e =>
                            setValue(`answer.${i}`, e.target.value)
                          }
                          rows={5}
                        ></textarea>
                      </div>
                    )}
                    {errors.answer?.[i] && (
                      <span className="form-text text-danger">
                        {errors.answer?.[i].message}
                      </span>
                    )}
                  </>
                ))
              ) : step == "finish" ? (
                <Row>
                  {pharmacy?.map(item => (
                    <Col md={4} key={item.id}>
                      <Card
                        className="pharmacy-card"
                        style={{
                          boxShadow:
                            watch("pharmacy") == item.id
                              ? "0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06)"
                              : null,
                          border:
                            watch("pharmacy") == item.id
                              ? "2px solid #77a0e2"
                              : null,
                        }}
                        onClick={() => setValue("pharmacy", item.id)}
                      >
                        <CardImg
                          alt="Card image cap"
                          src={getImageUrl(item.logo)}
                          top
                          height={100}
                        />
                        <CardBody>
                          <b>{item.name}</b>
                          <div>{item.address_line_1}</div>
                          <div>{item.address_line_2}</div>
                          <div>
                            {item.city}, {item.state}, {item.postcode}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                  {errors.pharmacy && (
                    <span className="form-text text-danger">
                      {errors.pharmacy[0]}
                    </span>
                  )}
                </Row>
              ) : (
                <>
                  <h3 className="text-center mb-4">
                    Prescription Received – Processing Your Order
                  </h3>
                  <p>
                    We are confirm that the script is sent to{" "}
                    {pharmacy?.find(item => item.id == watch("pharmacy"))?.name}{" "}
                    (
                    {
                      pharmacy?.find(item => item.id == watch("pharmacy"))
                        ?.website
                    }
                    ) and they have received the prescription form for you and
                    they will reach out to you regarding the dispensing steps in
                    the next business days.
                  </p>
                  <p>
                    If there are any discrepancies or if you need to provide
                    additional information, please contact the pharmacy.
                  </p>
                  <p>
                    Thank you for choosing{" "}
                    {pharmacy?.find(item => item.id == watch("pharmacy"))?.name}
                    . We look forward to serving you.
                  </p>
                  <p>
                    Regards, <br />
                    Aeris Health
                  </p>
                </>
              )}
            </form>
          </div>
        )}
      </div>
      {step != "complete" ? (
        <div className="modal-footer">
          <div
            className={`w-100 d-md-flex justify-content-md-${
              step == "finish" || step == 0 || step == 1 ? "between" : "end"
            }`}
          >
            {step == "finish" || step == 0 || step == 1 ? (
              <button
                type="button"
                className="btn btn-primary w-md"
                onClick={e => {
                  e.preventDefault()
                  if (step == 0) {
                    return setStep("start")
                  } else if (step == 1) {
                    return setStep(0)
                  } else {
                    return setStep(1)
                  }
                }}
              >
                Prev
              </button>
            ) : null}
            <button
              type="submit"
              className="btn btn-primary w-md"
              disabled={submitLoading}
              onClick={async e => {
                e.preventDefault()
                if (step == "start") {
                  return setStep(0)
                } else if (step == 0) {
                  if (
                    (getValues("value_type") == "Document" &&
                      getValues("files").length == 0) ||
                    !getValues("doctor_brand")
                  ) {
                    return showToast("All field is required", "error")
                  }
                  if (await trigger()) {
                    return setStep(1)
                  }
                } else if (step == 1) {
                  if (await trigger()) {
                    return setStep("finish")
                  }
                } else if (step == "finish") {
                  if (!getValues("pharmacy")) {
                    return showToast("All field is required", "error")
                  }
                  if (await trigger()) {
                    return handleSubmit(onSubmit())
                  }
                } else if (step == "complete") {
                  return toggle()
                }
              }}
            >
              {submitLoading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>
                  {step < data?.questions?.length || step == "start"
                    ? "Next"
                    : "Submit"}
                </span>
              )}
            </button>
          </div>
        </div>
      ) : null}
    </Modal>
  )
}

QuestionaryModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
}

export default React.memo(QuestionaryModal)
