import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DataPatient from '../Patient/Tabs/DataPatient'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabPane,
  TabContent,
  NavItem,
  Nav,
  NavLink,
  Badge,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DataPatientNew from "./Tabs/DataPatientNew";
import DataPatientExist from "./Tabs/DataPatientExist";
import DataPatientScriptReminder from "./Tabs/DataPatientScriptReminder";
import DataPatientReferralReminder from "./Tabs/DataPatientReferralReminder";
import DataPatientArchives from "./Tabs/DataPatientArchive";
import { get } from "helpers/api_helper";
import { useQuery } from "@tanstack/react-query";
import api from "helpers/api";
import { getRoles } from "@testing-library/react";
import { getRole } from "helpers/util";

const Appointment = () => {
  let params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('1')
  const { data, refetch: getData, isLoading } = useQuery(['patient-count'], () => api.getPatientCount(), {
    refetchOnWindowFocus: false,
  });

  const handleTab = tab => {
    if (params.has('tabs')) {
      history.push('patients')
    }
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (params.get('tabs')) {
      setActiveTab(params.get('tabs'))
    }
  }, [params, data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Aeris Health</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Patient" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {getRole() != 'Doctor' && (
                    <Nav className="icon-tab nav-justified">
                      {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={{
                            active: activeTab === "1",
                          }}
                          onClick={() => {
                            handleTab("1");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            <i className="fas fa-user"></i> Patient
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="fas fa-user"></i>
                          </span>
                        </NavLink>
                      </NavItem> */}
                      {/* {(getRole() == 'Admin') ? (
                        <>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={{
                                active: activeTab === "2",
                              }}
                              onClick={() => {
                                handleTab("2");
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="fas fa-user"></i> New Patient
                              <Badge className="bg-danger ms-1"> {data?.new ?? 0}</Badge>
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="fas fa-user"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={{
                                active: activeTab === "3",
                              }}
                              to="/patients"
                              onClick={() => {
                                handleTab("3");
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="fas fa-user"></i> Exist Patient
                                <Badge className="bg-danger ms-1"> {data?.exist ?? 0}</Badge>
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="fas fa-user"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                        </>
                      ) : null} */}
                      {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={{
                            active: activeTab === "4",
                          }}
                          onClick={() => {
                            handleTab("4");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            <i className="fas fa-user"></i> Script Reminder
                            <Badge className="bg-danger ms-1"> {data?.script ?? 0}</Badge>
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="fas fa-user"></i>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={{
                            active: activeTab === "5",
                          }}
                          onClick={() => {
                            handleTab("5");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            <i className="fas fa-user"></i> Referral Reminder
                            <Badge className="bg-danger ms-1"> {data?.referral ?? 0}</Badge>
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="fas fa-user"></i>
                          </span>
                        </NavLink>
                      </NavItem> */}
                      {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={{
                            active: activeTab === "6",
                          }}
                          onClick={() => {
                            handleTab("6");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            <i className="fas fa-user"></i> Archives
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="fas fa-user"></i>
                          </span>
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  )}

                  <TabContent
                    activeTab={activeTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      {activeTab == 1 && (
                        <DataPatient />
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      {activeTab == 2 && (
                        <DataPatientNew />
                      )}
                    </TabPane>
                    <TabPane tabId="3">
                      {activeTab == 3 && (
                        <DataPatientExist />
                      )}
                    </TabPane>
                    {/* <TabPane tabId="4">
                      {activeTab == 4 && (
                        <DataPatientScriptReminder />
                      )}
                    </TabPane>
                    <TabPane tabId="5">
                      {activeTab == 5 && (
                        <DataPatientReferralReminder />
                      )}
                    </TabPane> */}
                    <TabPane tabId="6">
                      {activeTab == 6 && (
                        <DataPatientArchives />
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Appointment.propTypes = {
  appointments: PropTypes.array,
  onGetAppointments: PropTypes.func,
  onAddNewAppointment: PropTypes.func,
  onDeleteAppointment: PropTypes.func,
  onUpdateAppointment: PropTypes.func,
};

export default withRouter(Appointment);

