import { del } from 'helpers/api_helper';
import { formatDate, getRole } from 'helpers/util';
import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from 'reactstrap';
import CreateUpdateSpecialist from './CreateUpdateSpecialist';
import DeleteConfirmation from 'components/Alert/DeleteConfirmation';
import api from 'helpers/api';
import { useQuery } from '@tanstack/react-query';

const Specialist = () => {
  const [id, setId] = useState('')
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [showDelete, setShowDelete] = useState(false)
  const { data, refetch: getData, isLoading } = useQuery(['specialist', search, page], () => api.getSpecialist({
    params: {
      page: page,
      search: search,
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const deleteAction = async () => {
    const specialist = await del(`/specialists/${id}`);
    setShowDelete(false)
    getData();
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleCreateModal = () => {
    toggle();
    setModalData(null)
  };

  const handleUpdateModal = (data) => {
    toggle();
    setModalData(data)
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(orders),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
      order: 'desc',
    },
  ];

  const EcommerceGPColumns = toggleModal => [
    {
      dataField: "specialist_name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDate(row.created_at),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>
              Action
            </DropdownToggle>
            <DropdownMenu>
              {(getRole() == 'Admin' || getRole() == 'Staff') && (
                <DropdownItem onClick={() => handleUpdateModal(row)}>
                  Edit
                </DropdownItem>
              )}
              {getRole() == 'Admin' && (
                <DropdownItem onClick={() => {
                  setId(row.id);
                  setShowDelete(true);
                }}>
                  Delete
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Aeris Health</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Specialist" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 align-items-center">
                    <Col sm="2">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            placeholder="Search..."
                            className="form-control"
                            onInput={(e) => {
                              setSearch(e.target.value);
                            }}
                            value={search}
                            type="text"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="10">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={handleCreateModal}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add New Specialist
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    <Row>
                      <Col sm="12">
                          {data && (
                            <PaginationProvider
                              pagination={paginationFactory(pageOptions)}
                              keyField="id"
                              columns={EcommerceGPColumns()}
                              data={data.data}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  data={data.data}
                                  columns={EcommerceGPColumns()}
                                  bootstrap4
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField="id"
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              selectRow={selectRow}
                                              classes={
                                                "table align-middle table-nowrap table-check"
                                              }
                                              headerWrapperClasses={"table-light"}
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-md-center mt-30">
                                        <Col className="pagination pagination-rounded justify-content-start inner-custom-pagination">
                                          Showing {data.from} to {data.to} of {data.total} entries
                                        </Col>
                                        <Col className="pagination pagination-rounded justify-content-end inner-custom-pagination">
                                          <Pagination options={{ numberButtonClass: "page-item d-flex" }}changePage={(e) => setPage(e.page)} data={data} />
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          )}
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateUpdateSpecialist modal={modal} toggle={toggle} handleCreateModal={handleCreateModal} data={modalData} refresh={getData} />
        <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />
      </div>
    </React.Fragment>
  )
}

export default React.memo(Specialist);