import React, { useEffect, useState, useCallback } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { Pagination } from 'react-laravel-paginex'
import { Link, withRouter } from "react-router-dom";
import { debounce } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { del, get } from "helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Spinner,
  Label,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { currency, formatDate, formatDateTime, getRole, showToast } from "helpers/util";
import CreateUpdateInvoice from "./CreateUpdateInvoice";
import Payment from "./Payment";
import ModalNotes from "./ModalNotes";
import DeleteConfirmation from "components/Alert/DeleteConfirmation";
import Confirmation from "components/Alert/Confirmation";
import LogHistory from "./LogHistory";
import { useQuery } from "@tanstack/react-query";
import api from "helpers/api";

const Invoice = () => {
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalNotes, setModalNotes] = useState(false)
  const [modalLog, setModalLog] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [showConfirmRefund, setShowConfirmRefund] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const { data: invoices, refetch, isLoading } = useQuery(['get-invoice', search, page, limit], () => api.getInvoices({
    params: {
      page: page,
      search: search,
      limit,
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const resendReceiptAction = useCallback(async (invId) => {
    const invoice = await get(`/resend-invoice/${invId}`);
    showToast(invoice.message, !invoice.status && 'error')
  }, [])

  const submitRefund = async () => {
    const invoice = await get(`/invoice/refund/${id}`);
    setShowConfirmRefund(false)
    refetch();
  }

  const deleteAction = async () => {
    const invoice = await del(`/invoice/${id}`);
    setShowDelete(false)
    refetch();
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const togglePayment = () => {
    if (modalPayment) {
      setModalPayment(false);
    } else {
      setModalPayment(true);
    }
  };

  const toggleNotes = () => {
    if (modalNotes) {
      setModalNotes(false);
    } else {
      setModalNotes(true);
    }
  };

  const toggleLog = () => {
    if (modalLog) {
      setModalLog(false);
    } else {
      setModalLog(true);
    }
  };

  const handleCreateModal = () => {
    toggle();
    setModalData(null)
  };

  const handleUpdateModal = (data) => {
    toggle();
    setModalData(data)
  };

  const handlePaymentModal = (data) => {
    togglePayment();
    setModalData(data)
  };

  const handleNotesModal = (data) => {
    toggleNotes();
    setModalData(data)
  };

  const handleLogModal = (data) => {
    toggleLog();
    setModalData(data)
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: limit,
    pageStartIndex: 1,
    totalSize: invoices?.total, // replace later with size(orders),
    custom: true,
  };

  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/invoices/${row.id}`}>#{row.id}</Link>
      ),
    },
    {
      dataField: "user.fullname",
      text: "Client",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/patients/${row.user?.id}`}>{row.user?.fullname}</Link>
      ),
    },
    {
      dataField: "appointment.doctor.fullname",
      text: "Doctor",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <span className="text-center">{row.appointment?.doctor ? row.appointment.doctor?.fullname : '-'}</span>
      ),
    },
    {
      dataField: "appointment.date",
      text: "Appointment Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.appointment ? <div className="text-center">
          {formatDateTime(row.appointment?.date)}
          <span className="text-danger d-block">{row.appointment.is_reschedule ? 'Reschedule' : ''}</span>
        </div> : '-'
      ),
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      formatter: (cellContent, row) => currency(row.total),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${row.status == 'PAID' ? 'success' : row.status == 'PARTIALLY' ? 'warning' : 'danger'}`}
          color={row.status}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "created_at",
      text: "Created",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDateTime(row.created_at),
    },
    {
      dataField: "approved_by",
      text: "Approved By",
      sort: true,
      hidden: getRole() == 'Patient' ? true : false
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          {((row.paid_amount - row.total < 0) && getRole() == 'Patient') ? (
            <a className="btn btn-primary w-md" onClick={() => handlePaymentModal(row)}>
              Pay Now
            </a>
          ) : (
            getRole() != 'Patient' && (
              <UncontrolledButtonDropdown direction="start">
                <DropdownToggle caret>
                  Action
                </DropdownToggle>
                <DropdownMenu>
                  {row.paid_amount - row.total < 0 && (
                    <DropdownItem onClick={() => handlePaymentModal(row)}>
                      Pay Now
                    </DropdownItem>
                  )}
                  {row.paid_amount - row.total > 0 && (
                    <DropdownItem onClick={() => {
                      setId(row.id);
                      setShowConfirmRefund(true);
                    }}>
                      Refund
                    </DropdownItem>
                  )}
                  {(getRole() == 'Admin' || getRole() == 'Staff') && (
                    <>
                      <DropdownItem onClick={() => handleUpdateModal(row)}>
                        Edit
                      </DropdownItem>
                      {row.payment[0]?.receipt_url && (
                        <DropdownItem>
                          <a href={row.payment[0]?.receipt_url} target="_blank" rel="noreferrer">Stripe Payment 1</a>
                        </DropdownItem>
                      )}
                      {row.payment[1]?.receipt_url && (
                        <DropdownItem>
                          <a href={row.payment[1]?.receipt_url} target="_blank" rel="noreferrer">Stripe Payment 2</a>
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={() => handleNotesModal(row)}>
                        Notes
                      </DropdownItem>
                      <DropdownItem onClick={() => handleLogModal(row)}>
                        Log History
                      </DropdownItem>
                    </>
                  )}
                  {getRole() == 'Admin' && (
                    <DropdownItem onClick={() => {
                      setId(row.id);
                      setShowDelete(true);
                    }}>
                      Delete
                    </DropdownItem>
                  )}
                  {row.status == 'PAID' || row.status == 'PARTIALLY' && (
                    <>
                      <DropdownItem onClick={() => {
                        resendReceiptAction(row.id);
                      }}>
                        Resend Receipt
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )
          )}
        </>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
      order: 'desc',
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Aeris Health</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Invoice" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            placeholder="Search..."
                            className="form-control"
                            onInput={(e) => {
                              setSearch(e.target.value);
                            }}
                            value={search}
                            type="text"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {getRole() != 'Patient' && (
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={handleCreateModal}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Add New Invoice
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-3 col-form-label me-n4"
                        >
                          Show
                        </Label>
                        <Col md={3}>
                          <select
                            id="formrow-limit"
                            className="form-control"
                            onChange={(e) => setLimit(e.target.value)}
                            defaultValue={limit}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </Col>
                        <Label className="col-3 col-form-label">
                          entries
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    invoices && invoices.data && (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={EcommerceOrderColumns(toggle)}
                        data={invoices.data}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={invoices.data}
                            columns={EcommerceOrderColumns(toggle)}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <Pagination options={{ numberButtonClass: "page-item d-flex" }}changePage={(e) => setPage(e.page)} data={invoices} />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Confirmation showConfirm={showConfirmRefund} setShowConfirm={() => setShowConfirmRefund(false)} action={submitRefund} />
          <CreateUpdateInvoice modal={modal} toggle={toggle} handleCreateModal={handleCreateModal} data={modalData}
          refresh={refetch} />
          <Payment modal={modalPayment} toggle={togglePayment} data={modalData} refresh={refetch} />
          <ModalNotes modal={modalNotes} toggle={toggleNotes} data={modalData} />
          <LogHistory modal={modalLog} toggle={toggleLog} data={modalData} />
          <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />
        </Container>
      </div>
    </React.Fragment>
  );
};

Invoice.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
};

export default withRouter(Invoice);

