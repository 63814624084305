import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { FormProvider, useForm } from "react-hook-form";
import GeneralSetting from "./components/GeneralSetting";
import api from "helpers/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { showToast } from "helpers/util";
import EmailTemplate from "./components/EmailTemplate";

const Setting = () => {
  const [activeTab, setActiveTab] = useState("1")
  const methods = useForm();

  const { data, refetch, isLoading } = useQuery(['get-setting'], () => api.getSetting(), {
    refetchOnWindowFocus: false
  });

  const { mutate, isLoading: submitLoading } = useMutation((data) => api.updateSetting(data), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
      } else {
        if (res.errors) {
          setErrors(res.errors)
        } else {
          showToast(res.message, 'error')
        }
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit', 'error')
    }
  });

  const handleSubmit = () => {
    var formData = new FormData();
    for (const key in methods.getValues()) {
      if (methods.getValues()[key]) {
        formData.set(`data[${key}]`, methods.getValues()[key]);
      }
    }
    mutate(formData)
  }

  const handleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Aeris Health</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Setting" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === "1" ? "active" : null}
                        onClick={() => {
                          handleTab("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-wrench"></i> General Settings
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === "2" ? "active" : null}
                        onClick={() => {
                          handleTab("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="bx bx-skip-next-circle"></i> Email Template
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          activeTab === "2" ? 'active' : null
                        }
                        onClick={() => {
                          handleTab("2");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className='bx bx-skip-next-circle'></i> Today Appointment
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          activeTab === "3" ? 'active' : null
                        }
                        onClick={() => {
                          handleTab("3");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-user"></i> Finish Appointment
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-user"></i>
                        </span>
                      </NavLink>
                    </NavItem> */}
                  </Nav>

                  {isLoading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    <FormProvider {...methods} autoComplete="off">
                      <TabContent
                        activeTab={activeTab}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId="1">
                          {activeTab == 1 && <GeneralSetting data={data} />}
                        </TabPane>
                        <TabPane tabId="2">
                          {activeTab == 2 && <EmailTemplate data={data} />}
                        </TabPane>
                        {/* <TabPane tabId="2">{activeTab == 2 && <></>}</TabPane>
                        <TabPane tabId="3">{activeTab == 3 && <></>}</TabPane> */}
                      </TabContent>
                      <Button
                        color="primary"
                        className="float-end"
                        onClick={handleSubmit}
                      >
                        Save Changes
                      </Button>
                    </FormProvider>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
};

export default withRouter(Setting);

