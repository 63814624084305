import React, { useState } from "react"
import PropTypes from "prop-types";
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap"

//Import Images
import profile1 from "assets/images/profile-img.png"
import Activity from "../../components/Activity";
import ScriptReminder from "../../components/ScriptReminder";
import ReferralLetter from "../../components/ReferralLetter";
import HistoryTable from "../../components/HistoryTable";
import UpdateDetail from "../../UpdateDetail";
import { getRole } from "helpers/util";
import PatientMenu from "./PatientMenu";
import CreateUpdatePatient from "pages/Patient/CreateUpdatePatient";
import { useQuery } from "@tanstack/react-query";
import api from "helpers/api";
import QuestionaryModal from "components/QuestionaryModal";
import PatientNote from "./PatientNote";
// import projectColumns from "./projectColumns"

const Profile = ({data, refetch, errors, disableInput = false}) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null)

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleDetailUpdate = () => {
    toggle();
    setModalData(null)
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm="12">
          <CardText className="mb-0">
            <Row>
              <Col xl="6">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Welcome Back!</h5>
                          <p>Have a nice day !</p>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        <img src={profile1} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="4">
                        <div className="avatar-md profile-user-wid mb-4">
                          <img
                            src={`https://via.placeholder.com/80x80?text=${
                              data?.user?.name[0] ??
                              "" + data?.user?.last_name[0] ??
                              ""
                            }`}
                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                        <h5 className="font-size-15 text-truncate">
                          {data?.user?.name}
                        </h5>
                        <p className="text-muted mb-0 text-truncate">
                          {data?.type_patient?.name_type}
                        </p>
                      </Col>

                      <Col sm={8}>
                        <div className="pt-4">
                          {/* <Row>
                            <Col xs="6">
                              <h5 className="font-size-15">
                                {data?.user?.invoice.length} Invoice
                              </h5>
                            </Col>
                            <Col xs="6">
                              <h5 className="font-size-15">
                                {data?.user?.appointment.length} Appointment
                              </h5>
                            </Col>
                          </Row> */}
                          {/* <div className="mt-4">
                            <Button
                              className="btn btn-primary btn-sm"
                              color="primary"
                              onClick={() => {
                                toggle();
                                setModalData(data)
                              }}
                            >
                              Edit Details{" "}
                              <i className="mdi mdi-arrow-right ms-1" />
                            </Button>
                          </div> */}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">Personal Information</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{data?.user?.fullname}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{data?.user?.phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{data?.user?.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Address :</th>
                            <td>{data?.user?.address}</td>
                          </tr>
                          <tr>
                            <th scope="row">Gender :</th>
                            <td>{data?.gender}</td>
                          </tr>
                          <tr>
                            <th scope="row">Birth Date :</th>
                            <td>{data?.b_date}</td>
                          </tr>
                          {getRole() != "Patient" && data?.is_flag ? (
                            <tr>
                              <th scope="row" className="text-danger">
                                Flag Notes :
                              </th>
                              <td className="text-danger">
                                {data?.flag_message}
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* {getRole() != 'Patient' ? (
              <Row>
                <Col>
                  <PatientMenu />
                </Col>
              </Row>
            ) : null} */}
            <Row>
              {/* <Col xl="4">
                <Activity />
              </Col> */}
              {/* {getRole() != "Patient" ? (
                <>
                  <Col xl="6">
                    <ReferralLetter data={data} refetch={refetch} />
                  </Col>

                  <Col xl="6">
                    <ScriptReminder data={data} refetch={refetch} />
                  </Col>
                </>
              ) : null} */}
            </Row>

            {/* {disableInput == false ? (
              <>
                <Row>
                  <Col xl="12">
                    <PatientNote data={data} getData={refetch} />
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <HistoryTable data={data} getData={refetch} />
                  </Col>
                </Row>
              </>
            ) : null} */}
          </CardText>
        </Col>
      </Row>
      {/* <UpdateDetail modal={modal} toggle={toggle} handleDetailUpdate={handleDetailUpdate} data={modalData} refresh={refetch} /> */}
      <CreateUpdatePatient
        modal={modal}
        toggle={toggle}
        handleCreateModal={handleDetailUpdate}
        data={modalData}
        refresh={refetch}
      />
    </React.Fragment>
  )
}

Profile.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
  disableInput: PropTypes.bool,
};

export default React.memo(Profile);