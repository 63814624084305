import moment from "moment";
import toastr from "toastr";

const BASE_IMAGE_URL = "https://api.aeris.health/storage/uploads/"

export function currency(value) {
  const formattedCurrency = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
  }).format(value);
  return formattedCurrency;
}
export function formatDate(date) {
  const date1 = moment(date).format("DD MMM Y");
  return date1 == 'Invalid date' ? '' : date1;
}
export function formatDateInput(date) {
  const date1 = moment(date).format("YYYY-MM-DD");
  return date1 == 'Invalid date' ? '' : date1;
}
export function formatDateTime(date) {
  if (!date) {
    return null;
  }
  const date1 = moment(new Date(date)).format("DD MMM Y hh:mm A");
  return date1 == 'Invalid date' ? '' : date1;
}
export function formatDateTimeInput(date) {
  const date1 = moment(new Date(date)).format("YYYY-MM-DDTHH:mm");
  return date1 ?? null;
}
export function getImageUrl(path) {
  if (path) {
    return BASE_IMAGE_URL + path
  }
}
export function showToast(message, type = null) {
  toastr.options = {
    positionClass: 'toast-top-right',
    timeOut: 3000,
    closeButton: true,
    progressBar: true,
    newestOnTop: true,
    showEasing: 'swing',
    showDuration: true,
  };

  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (type === "info") toastr.info(message, 'Information');
  else if (type === "warning") toastr.warning(message, 'Warning!');
  else if (type === "error") toastr.error(message, 'Error!');
  else toastr.success(message, 'Success!');
}
export function findPage(page, activePage, data) {
  if (page == '>') {
    return activePage + 1 ;
  } else if (page == '>>') {
    return data.last_page;
  } else if (page == '<') {
    return activePage - 1;
  } else if (page == '<<') {
    return 1;
  } else {
    return page;
  }
}
export function getRole() {
  const user = JSON.parse(localStorage.getItem('authUser'))?.user;
  if (user?.role_id == 1) {
    return 'Admin'
  } else if (user?.role_id == 2) {
    return 'Staff'
  } else if (user?.role_id == 3) {
    return 'Doctor'
  } else if (user?.role_id == 4) {
    return 'Nurse'
  } else if (user?.role_id == 5) {
    return 'Patient'
  }else{
    return 'Not Authenticated';
  }
}

export function getUserRole(role_id) {
  if (role_id == 1) {
    return 'Admin'
  } else if (role_id == 2) {
    return 'Staff'
  } else if (role_id == 3) {
    return 'Doctor'
  } else if (role_id == 4) {
    return 'Nurse'
  } else if (role_id == 5) {
    return 'Patient'
  }else{
    return 'Not Authenticated';
  }
}

export function getUrl(url) {
  if (url) {
    const { hostname } = new URL(url.replace(' ', '-'));
    if (hostname == 'management.aeris.health') {
      return url?.replace('management.aeris.health', 'api.aeris.health').replace('http://', 'https://')
    }
    return url?.replace('http://', 'https://');
  }else{
    return null;
  }
}

export function toFormdata(data, update = false) {
  let formData = new FormData()
  for (let key in data) {
    if (data[key]) {
      if (typeof data[key] === "object") {
        if (data[key]?.length) {
          for (let subKey in data[key]) {
            formData.append(`${key}[]`, data[key][subKey])
          }
        } else {
          formData.append(key, data[key])
        }
      } else if (data[key] != "null" && data[key] != null && data[key] != "") {
        formData.append(key, data[key])
      }
    } else {
      formData.append(key, "")
    }
  }

  if (update) {
    formData.append('_method', 'PUT')
  }

  return formData;
}